<template>
  <div>
    <label
      v-if="label && !hideLabel"
      :for="props.id"
      class="block text-sm font-medium leading-6"
    >
      {{ label }}
    </label>
    <div :class="[label ? 'mt-2' : '']">
      <div class="relative items-center">
        <div
          v-if="$slots.prepend"
          class="absolute left-0 top-0 bottom-0 w-10 flex items-center justify-center"
        >
          <slot name="prepend"></slot>
        </div>
        <Field
          :id="props.id"
          v-model="value"
          v-mask="props.mask || (props.type === 'tel' ? telMask : false)"
          :name="name"
          :label="label"
          :type="type"
          :autocomplete="props.autocomplete"
          :required="props.required"
          :placeholder="props.placeholder"
          :disabled="disabled"
          :min="min"
          class="input-field block w-full py-1.5 shadow-sm sm:text-sm sm:leading-6"
          :class="[
            inputClass,
            (error || errorMessage || fieldError) && 'input-field-error',
            disabled && 'ring-[#D9E8E8] bg-gray cursor-not-allowed',
            $slots.prepend && 'pl-10',
            isSuccess && 'input-field-success'
          ]"
          @focus="isFocused = true"
          @blur="isFocused = false"
        />
        <div
          v-if="props.type === 'password' && !disabled"
          class="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
          @click="switchPasswordVisibility"
        >
          <div class="h-4 w-4 text-gray-mud">
            <EyeSlashIcon v-if="isVisiblePassword" />
            <EyeIcon v-else />
          </div>
        </div>
        <span
          v-if="props.type === 'date' && showPlaceholderForDateInput"
          class="absolute inset-y-0 left-0 text-sm leading-4 h-fit text-gray-700 ml-3 mt-3 bg-white"
          :class="fieldError ? 'bg-[#FFF5F5] text-red-hot' : ''"
          @click="inputRef?.focus()"
        >
          {{ props.placeholder }}
        </span>
      </div>
      <p
        v-if="(error && typeof error === 'string') || errorMessage"
        class="text-xs text-red-hot mt-2"
      >
        {{ error || errorMessage }}
      </p>
      <ErrorMessage
        v-else
        :name="name"
        class="text-xs text-red-hot mt-2"
        :as="'p'"
      />
    </div>
  </div>
</template>

<script setup>
import { toRef } from 'vue'
import {
  useField,
  Field,
  useIsFieldValid,
  useIsFormTouched,
  useFieldError
} from 'vee-validate'
import uniqueId from 'lodash/uniqueId'
import * as vueTheMask from 'vue-the-mask'
import { EyeIcon, EyeSlashIcon } from '@heroicons/vue/24/solid'
import { DateTime } from 'luxon'

const vMask = (el, binding) => {
  if (!binding.value) {
    return
  }
  vueTheMask.mask(el, binding)
}
const props = defineProps({
  required: {
    type: Boolean,
    default: false
  },
  name: {
    type: String,
    default: uniqueId('app-input-name-')
  },
  id: {
    type: String,
    default: uniqueId('app-input-id-')
  },
  type: {
    type: String,
    default: 'text'
  },
  label: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  modelValue: {
    type: [String, Number],
    default: ''
  },
  mask: {
    type: [String, Boolean],
    default: false
  },
  rules: {
    type: String,
    default: undefined
  },
  error: {
    type: [String, Boolean],
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  inputClass: {
    type: String,
    default: ''
  },
  min: {
    type: Number,
    default: null
  },
  hideLabel: {
    type: Boolean,
    default: false
  }
})

const inputRef = ref(null)
const name = toRef(props, 'name')
const type = toRef(props, 'type')

const { value, errorMessage } = useField(() => name.value, props.rules, {
  syncVModel: true
})

const fieldError = useFieldError(() => name.value)

const isValid = useIsFieldValid(name)
const isTouched = useIsFormTouched(name)
// const isDirty = useIsFieldDirty(name)
const isSuccess = computed(() => isTouched.value && isValid.value)

const telMask = '+38 (0##) ###-##-##'

const isVisiblePassword = ref(false)
const isFocused = ref(false)

const switchPasswordVisibility = () => {
  isVisiblePassword.value = !isVisiblePassword.value
  type.value = isVisiblePassword.value ? 'text' : 'password'
}

onMounted(() => {
  inputRef.value = document.getElementById(props.id)
  if (type.value === 'date') {
    inputRef.value?.setAttribute(
      'max',
      DateTime.now().minus({ days: 1 }).toISODate()
    )
  }
})

const showPlaceholderForDateInput = computed(
  () => props.placeholder && !value.value && !isFocused.value
)
</script>
